
  const userEditorScriptEntry = { editorReady() {} }
  const editorScriptEntry = userEditorScriptEntry;

  

  import { initI18nWithoutICU as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
import { createExperiments, createWidgetExperiments } from '@wix/yoshi-flow-editor/runtime/esm/experiments';
    

  
import { createHttpClient } from '@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp';
    


  
  import { editorReadyWrapper, onEventWrapper } from '@wix/yoshi-flow-editor/runtime/esm/editorScript.js';

  let editorReady = editorScriptEntry.editorReady;
  const onEvent = onEventWrapper({ onEvent: editorScriptEntry.onEvent });
  const sentryConfig = {
    DSN: 'https://01fb0c955b864231aa35321b27af56a8@sentry.wixpress.com/440',
    id: '01fb0c955b864231aa35321b27af56a8',
    projectName: 'spotify',
    teamName: 'music',
    
  };
  const experimentsConfig = {"scopes":["spotify"]};
  const translationsConfig = {"icuEnabled":false,"defaultTranslationsPath":"/home/builduser/agent00/work/ad4ac254fb89a706/spotify-ooi/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hu","id","it","ja","ko","lt","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};
  const defaultTranslations = {"searcher.album":"Albums","searcher.song":"Tracks","searcher.playlist":"Playlists","searcher.artist":"Artists","searcher.select":"Select","searcher.cancel":"Cancel","search.start.searchHeader":"Search for albums, tracks, playlists or artists","settings.tabs.main":"Main","settings.tabs.settings":"Settings","settings.main.loginInfo":"Spotify log-in is required","settings.main.loginButton":"Log-in","settings.main.searchSpotifyButton":"Search Spotify","settings.main.intro":"Stream your favorite music from Spotify directly on your website. Search albums, tracks, playlists or artists.","settings.settings.spotifyUriTitle":"Spotify URI","settings.settings.spotifyUriPlaceholder":"Paste it here","settings.settings.loginButton":"Log-in","settings.settings.updateButton":"Update","settings.settings.popupInfo":"To find a Spotify URI: enter the Spotify desktop app > click the 3 dots on your track, album or playlist > click Share > and select Copy Spotify URI.","settings.settings.popupError":"Check the Spotify URI and try again","settings.settings.search":"Search Spotify","settings.settings.changeMusic":"Change Music","settings.settings.spotifyTitle":"Search Spotify","search.error.title":"Something went wrong","search.error.description":"Please try again later.","search.noResults.track":"No results found in Tracks","search.noResults.album":"No results found in Albums","search.noResults.artist":"No results found in Artists","search.noResults.playlist":"No results found in Playlists","search.noResults.tryDifferent":"Try searching again using a different spelling or keyword.","search.tableView.number":"NO.","search.tableView.track":"TRACK NAME","search.tableView.artist":"ARTIST NAME","search.tableView.album":"ALBUM","search.textInput.placeholder":"Search","search.textInput.xTitle":"Search","search.textInput.inputTitle":"Type what you want to find then press Enter"};
  if (editorReady) {
    editorReady = editorReadyWrapper({
      editorReady,
      sentryConfig,
      experimentsConfig,
      translationsConfig,
      defaultTranslations,
      shouldUseEssentials: false,
      artifactId: 'spotify-ooi',
      appDefId: '099ac204-be88-4ed1-89af-cf14358c83f1',
      optionalDeps: {
        initI18n,
        createHttpClient,
        createExperiments,
      },
      localeDistPath: 'assets/locales',
    });
    if (false) {
      const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
      editorReady = hot(module, editorReady)
    }
  }
  export { editorReady };
  export { onEvent };
  export const exports = editorScriptEntry.exports;
  export const getAppManifest = editorScriptEntry.getAppManifest;
  export const handleAction = editorScriptEntry.handleAction;
  
